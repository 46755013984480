@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.orderPlate{
    width: 100%;
    @extend %radius;
    @extend %shadow;
    height: 227px;
    margin-bottom: 40px;
    padding: 22px;
    @media (max-width: 1024px) {
        height: 220px;
        margin-bottom: 25px;
    }
    @media (max-width: 520px) {
        height: 204px;
        margin-bottom: 15px;
        padding: 15px;
    }
    @media (max-width: 340px) {
        height: 184px;
    }
}

.orderPlateTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderPlateTitleCount{
    display: flex;
    align-items: center;
}

.orderCode{
    @extend %text1;
    color: $textDarkL1;
}

.orderCountProducts{
    @extend %text3;
    color: $textGrayL3;
    cursor: default;
    margin-left: 15px;
    @media (max-width: 520px) {
        margin-left: 10px;
    }
    @media (max-width: 324px) {
        display: none;
    }
}

.orderPrice{
    @extend %text1;
    color: $accentSuper;
    cursor: default;
}

.orderImgPlate{
    width: 100%;
    height: 110px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-content: start;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 340px) {
        height: 90px;
    }
}

.orderImg{
    height: 50px;
    margin-right: 10px;
    cursor: pointer;
    @media (max-width: 340px) {
        height: 40px;
    }
}

.orderDate{
    @extend %text3;
    color: $textGrayL3;
}

