@import '../../../styles/colors';
@import '../../../styles/typography';

.textElement{
    cursor: pointer;
    @extend %text3;
    color: $textGrayL3;
}

.textElement:hover{
    color: $textGrayL2;
}

.textElement:active{
    color: $accentSuper;
}
