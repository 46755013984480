.body{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}
.pad{
    position: relative;
    width: 1620px;
    min-width: 945px;
    @media (max-width: 1625px) {
        margin-left: 5px; 
        margin-right: 5px;
    }
    @media (max-width: 1024px) {
        width: 944px; 
        min-width: 401px;
        margin-left: 0px;
        margin-right: 0px;
    }
    @media (max-width: 950px) {
        margin-left: 5px; 
        margin-right: 5px;
    }
    @media (max-width: 520px) {
        width: 400px; 
        min-width: 300px;
        margin-left: 0px; 
        margin-right: 0px;
    }
    @media (max-width: 405px) {
        margin-left: 5px; 
        margin-right: 5px;
    }
}