@import '../../../styles/colors';
@import '../../../styles/typography';

.textArrow{
    cursor: default;
    @extend %text3;
    color: $textGrayL3;
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 1024px) {
        margin-left: 7.5px;
        margin-right: 7.5px;
    }
    @media (max-width: 520px) {
        margin-left: 5px;
        margin-right: 5px;
    }
}