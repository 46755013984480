@import '../../../../styles/colors';
@import '../../../../styles/typography';

.return{
    width: 320px;
    @extend %text2;
    color: $textGrayL3;
    margin-bottom: 20px;
    line-height: 20px;
    cursor: pointer;
    @media (max-width: 1024px) {width: 295px;}
    @media (max-width: 520px) {width: 240px;}
    @extend %transition;
}
.return:hover{
    color: $textGrayL2;
}
.return:active{
    color: $accentSuper;
}