@import '../../../../../styles/colors';
@import '../../../../../styles/typography';


%margin{
    margin-top: 40px;
    @media (max-width: 1024px) {
        margin-top: 35px;
    }
    @media (max-width: 520px) {
        margin-top: 25px;
    }
}

.plate404{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    @media (min-width: 1024px) {
        height: calc(100vh - 680px);
    }
    @media (max-width: 1024px) {
        height: calc(100vh - 565px);
        min-height: 350px;
    }
    @media (max-width: 520px) {
        height: calc(100vh - 495px);
        min-height: 300px;
    }
}

.titleDesc{
    font-family: 'Comfortaa';
    width: 100%;
    @extend %title-font; 
    font-size: 24px;
    text-align: center;
    color: $textDarkL1;
    cursor: default;
    @extend %margin;
    @media (max-width: 1024px) {font-size: 20px;}
}

.titleDescMin{
    font-family: 'Comfortaa';
    width: 100%;
    @extend %title-font; 
    font-size: 16px;
    text-align: center;
    color: $textGrayL2;
    cursor: default;
    @extend %margin;
    margin-bottom: 100px;
    @media (max-width: 1024px) {font-size: 14px;}
}

.openCatalog{
    cursor: pointer;
    color: $textAccent;   
    @extend %no-select-text;
    @media (max-width: 520px) {color: $textGrayL2;}
}

.openCatalog:hover{
    color: $textDarkL1;    
}

.openCatalog:active{
    color: $accentSuper;   
    @media (max-width: 520px) {color: $textGrayL2;}
}