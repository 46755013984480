@import '../../../styles/colors';
@import '../../../styles/typography';

.singleTitle{
    width: 100%;
    cursor: default;
    margin-top: 40px;
    margin-bottom: 37px;
    @media (max-width: 1024px) {
        margin-top: 35px;
        margin-bottom: 32px;
    }
    @media (max-width: 520px) {
        margin-top: 25px;
        margin-bottom: 22px;
    }
}