@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.gridPlate{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 249px);
    grid-column-gap: calc((100% - (249px * 6))/5);
    grid-row-gap: 20px;
    @media (max-width: 1550px) {
        grid-template-columns: repeat(5, 249px);
        grid-column-gap: calc((100% - (249px * 5))/4);
    }
    @media (max-width: 1280px) {
        grid-template-columns: repeat(4, 249px);
        grid-column-gap: calc((100% - (249px * 4))/3);
    }
    @media (max-width: 1024px) {
        grid-template-columns: repeat(4, 209px);
        grid-column-gap: calc((100% - (209px * 4))/3);
    }
    @media (max-width: 875px) {
        grid-template-columns: repeat(3, 209px);
        grid-column-gap: calc((100% - (209px * 3))/2);
    }
    @media (max-width: 660px) {
        grid-template-columns: repeat(2, 209px);
        grid-column-gap: calc((100% - (209px * 2))/1);
    }
    @media (max-width: 520px) {
        grid-template-columns: repeat(2, 178px);
        grid-column-gap: calc((100% - (178px * 2))/1);
    }
    @media (max-width: 375px) {
        grid-template-columns: repeat(1, 178px);
        grid-column-gap: calc((100% - (178px * 1))/1);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    min-height: calc(100vh - 800px);
    @media (max-width: 1024px) {
        min-height: calc(100vh - 640px);
    }
    @media (max-width: 520px) {
        min-height: calc(100vh - 575px);
    }
}
