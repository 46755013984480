@import '../../styles/colors';
@import '../../styles/typography';

%margin{
    margin-top: 16px;
}

.cardPlate{
    position: relative;
    min-width: 249px;
    width: 249px;
    height: 440px;
    background: $backgroundWhite;
    //background: red;
    @extend %radius;
    @extend %shadow;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1024px) {
        min-width: 209px;
        width: 209px;
        height: 380px;
    }
    @media (max-width: 520px) {
        min-width: 178px;
        width: 178px;
        height: 350px;
    }
} 

.cardImage{
    @extend %margin;
    height: 220px;
    max-width: 229.5px;
    cursor: pointer;
    @media (max-width: 1024px) {
        max-width: 190px;
        height: 175px;
    }
    @media (max-width: 520px) {
        max-width: 173px;
        height: 155px;
    }
}

.cardTitle{
    @extend %margin;
    width: 229.5px;
    height: 40px;
    
    text-align: center;
    @extend %text1;
    color: $textDarkL1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 1024px) {
        width: 190px;
        height: 35px;
    }
    @media (max-width: 520px) {
        width: 173px;
        height: 30px;
    }
}

.cardTitle:hover{
    color: $accentSuper;
}

.cardTitle:active{
    color: $textAccent;
}

.cardTitleM{
    @extend %margin;
    width: 229.5px;
    height: 79px;
    
    text-align: center;
    @extend %text1;
    color: $textDarkL1;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 1024px) {
        width: 190px;
        height: 68px;
    }
    @media (max-width: 520px) {
        width: 173px;
        height: 64px;
    }
}

.cardTitleM:hover{
    color: $accentSuper;
}

.cardTitleM:active{
    color: $textAccent;
}

.cardAuthor{
    @extend %margin;
    width: 229.5px;
    //background-color: aqua;
    text-align: center;
    @extend %text2;
    color: $textGrayL2;
    cursor: pointer;
    @extend %no-transposition;
    @media (max-width: 1024px) {
        width: 190px;
    }
    @media (max-width: 520px) {
        width: 173px;
    }
}

.cardAuthor:hover{
    color: $textAccent;
}

.cardAuthor:active{
    color: $accentSuper;
}

.starsPublisherLine{
    margin-top: 18px;
    width: 229.5px;
    height: 22px;
    //background-color: aqua;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
        width: 190px;
    }
    @media (max-width: 520px) {
        width: 173px;
    }
}

.publisherName{
    color: $textGrayL3;
    margin-left: 3px;
    @extend %text3;
    cursor: pointer;
    text-align: right;
}

.publisherName:hover{
    color: $textGrayL2;
}

.publisherName:active{
    color: $textDarkL1;
}

.priceButtonsLine{
    @extend %margin;
    width: 229.5px;
    height: 40px;
    //background-color: aqua;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
        width: 190px;
    }
    @media (max-width: 520px) {
        width: 173px;
    }
}

.buttonsLine{
    width: 130px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {width: 115px}
    @media (max-width: 520px) {
        width: 110px;
    }
}

.price{
    @extend %text1;
    color: $accentSuper;
    cursor: default;
}

.discountPriceBottom{
    @extend %text1;
    color: $warning;
    cursor: default;
}

.discountPriceTop{
    position: absolute;
    left: 10px;
    z-index: 10;
    @extend %text3;
    color: $textGrayL2;
    cursor: default;
    bottom: 50px;
    @media (max-width: 1024px) {bottom: 40px;}
    @media (max-width: 520px) {bottom: 35px;}
}

.discountPriceTop:before {
	content: "";
	border-bottom: 1px solid $badgeSale;
	position: absolute;
	width: 95%;
    left: 2.5%;
	height: 50%;
    transform: rotate(10deg);
    border-radius: 360px;
}