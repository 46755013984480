.scroll{
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    justify-content: flex-start;
    flex-wrap: nowrap;
    scrollbar-width: none;
}

.scroll::-webkit-scrollbar {
    display: none;
}