@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.promotionImage{
    width: 100%;
    height: 500px;
    margin-bottom: 40px;
    @extend %radius;
    object-fit: cover;
    object-position: 50% 50%;
    @media (max-width: 1124px) {height: 460px;}
    @media (max-width: 1024px) {
        height: 290px;
        margin-bottom: 35px;
    }
    @media (max-width: 790px) {height: 250px;}
    @media (max-width: 590px) {height: 230px;}
    @media (max-width: 520px) {
        height: 170px;
        margin-bottom: 25px;
    }
    @media (max-width: 360px) {height: 155px;}
    @media (max-width: 330px) {height: 145px;}
}

.promotionsDescription{
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: start;
    }
}

.promotionsDescriptionDate{
    color: $textDarkL1;
    white-space: nowrap;
    margin-right: 40px;
    @media (max-width: 1024px) {
        margin-right: 4px;
        margin-bottom: 35px;
    }
    @media (max-width: 520px) {
        white-space: wrap;
        margin-bottom: 25px;
    }
}

.promotionsDescriptionText{
    text-align: right;
    color: $textGrayL3;
    @extend %text2;
    @media (max-width: 1024px) {
        text-align: left;
    }
}