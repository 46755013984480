@import '../../../../styles/colors';
@import '../../../../styles/typography';

.mainPlate{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.exitSvg{
    width: 25px;
    height: 25px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    fill: $textDarkL1;
    @media (max-width: 1024px) {
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px
    }
}

.exitSvg:hover{
    fill: $accentSuper;
}

.exitSvg:active{
    fill: $warning;
}

.imagesPlate{
    position: absolute;
    bottom: 160px;
    top: 75px;
    display: flex;
    width: 100%;
    @media (max-width: 1024px) {
        bottom: 125px;
        top: 50px
    }
    @media (max-width: 520px) {
        top: 25px;
        bottom: 115px;
    }
    transition: left 0.6s ease;
}

.imagesLineComponent{
    min-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagesMain{
    max-width: 95%;
    max-height: 90%;
    @extend %no-select-text;
}

.imagesListPlate{
    position: absolute;
    width: 100%;
    height: 115px;
    min-height: 115px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
        height: 95px;
        min-height: 95px;
        bottom: 15px;
    }
    @media (max-width: 520px) {
        bottom: 10px;
    }
}

.bottomImage{
    width: auto;
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;
    @media (max-width: 1024px) {
        margin-left: 10px;
        margin-right: 10px;
    }
    @media (max-width: 420px) {
        margin-left: 5px;
        margin-right: 5px;
    }
    @media (max-width: 340px) {
        margin-left: 2px;
        margin-right: 2px;
    }
    cursor: pointer;
    @extend %no-select-text;
}

.navigateButtons{
    position: absolute;
    left: 5%;
    width: 90%;
    top: 48%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    @media (max-width: 520px) {display: none;}
}

.tileHover:hover{
    background: $textAccent;
    color: $textLight;
}

.tileHover:active{
    background: $accentSuper;
    color: $textLight;
}