@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.imagesPlate{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media (max-width: 1320px) {height: 340px;}
    @media (max-width: 1024px) {
        margin-top: 35px;
        height: 255px;
    }
    @media (max-width: 724px) {height: 205px;}
    @media (max-width: 520px) {
        margin-top: 20px;
        height: 120px;
    }
    @media (max-width: 355px) {height: 100px;}
}

.image{
    width: 32%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    @extend %radius;
    
}