@import '../../styles/colors';
@import '../../styles/typography';

.reviewPlate{
    width: 100%;
    background: $backgroundLight;
    padding: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
        padding: 15px;
        margin-bottom: 15px;
    }
    @media (max-width: 520px) {
        margin-bottom: 10px;
    }
}

.reviewMyPlate{
    width: 100%;
    background: $reviewMyPlate;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    @media (max-width: 1024px) {
        padding: 15px;
        margin-bottom: 15px;
    }
    @media (max-width: 520px) {
        margin-bottom: 10px;
    }
}

.reviewName{
    color: $textGrayL3;
    cursor: default;
    margin-bottom: 20px;
    @media (max-width: 1024px) {margin-bottom: 15px;}
    @media (max-width: 1024px) {margin-bottom: 10px;}
}

.reviewHeader{
    color: $textDarkL1;
    margin-bottom: 20px;
    @media (max-width: 1024px) {margin-bottom: 15px;}
    @media (max-width: 1024px) {margin-bottom: 10px;}
}

.reviewText{
    color: $textDarkL1;
    margin-bottom: 15px;
    @extend %text3;
    @media (max-width: 1024px) {margin-bottom: 10px;}
    @media (max-width: 1024px) {margin-bottom: 5px;}
}