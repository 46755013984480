@import '../../../styles/colors';
@import '../../../styles/typography';

.BarArrow{
    width: auto;
    color: $textGrayL2;
    cursor: pointer;
    @extend %text1;
    margin-bottom: 20px;
}

.BarArrow:hover{
    color: $textGrayL3;
}
.BarArrow:active{
    color: $accentSuper;
}

.BarArrowMargin{
    margin-right: 10px;
    @media (max-width: 1024px) {margin-right: 7px;}
}
