@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.slideBack{
    width: 100%;
    height: 900px;
    @extend %radius;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
        margin-top: 35px;
        height: 530px;
    }
    @media (max-width: 520px) {
        margin-top: 20px;
        height: 500px;
    }
}

.slideNoBack{
    width: 100%;
    height: 500px;
    @extend %radius;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
        margin-top: 35px;
        height: 330px;
    }
    @media (max-width: 520px) {
        margin-top: 20px;
        height: 200px;
    }
}


.slideImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    @extend %radius;
}

.slideText{
    position: absolute;
    width: 1050px;
    height: auto;
    padding: 60px;
    @extend %radius;
    @extend %blur;
    @extend %shadow;
    background: $apmSlide;
    @media (max-width: 1320px) {width: 860px;}
    @media (max-width: 1024px) {
        padding: 40px;
        width: 700px;
    }
    @media (max-width: 800px) {width: 640px;}
    @media (max-width: 800px) {padding: 15px;}
    @media (max-width: 680px) {width: 580px;}
    @media (max-width: 580px) {width: 500px;}
    @media (max-width: 520px) {
        width: 380px;
        padding: 15px;
    }
    @media (max-width: 400px) {
        width: 280px;
    }
}

.slideTextStyle{
    color: $textLight;
    text-align: center;
    cursor: default;
    @media (max-width: 680px) {
        font-size: 15px;
    }
    @media (max-width: 400px) {
        font-size: 12px;
    }
}