@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.flexPlate{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.imagePlate{
    width: 240px;
    min-width: 240px;
    @extend %radius;
    margin-right: 45px;
    object-fit: cover;
    object-position: 50% 50%;
    @media (max-width: 1024px) {
        width: 200px;
        min-width: 200px;
        margin-right: 20px;
    }
    @media (max-width: 700px) {
        width: 160px;
        min-width: 160px;
        margin-right: 15px;
    }
}

.textPlate{
    width: 100%;
    background-color: $backgroundLight;
    padding: 30px;
    @extend %radius;
    @media (max-width: 1024px) {padding: 20px;}
    @media (max-width: 700px) {padding: 15px;}
    @media (max-width: 520px) {
        padding: 10px;
        display: flex;
        background: none;
        flex-direction: column;
        align-items: center;
    }
}

.nameTitle{
    color: $textDarkL1;
    margin-bottom: 15px;
    margin-right: 30px;
    cursor: default;
    @media (max-width: 1024px) {margin-bottom: 10px;}
    @media (max-width: 520px) {
        margin-right: 0px;
        text-align: center;
    }
    @media (max-width: 320px) {
        font-size: 15px;
    }
}

.nameDate{
    color: $textGrayL2;
    margin-bottom: 15px;
    @media (max-width: 1024px) {margin-bottom: 10px;}
    
}

.nameDesc{
    color: $textGrayL3;
    @extend %text2;
    @media (max-width: 520px) {display: none;}
}

.modileDesc{
    display: none;
    @extend %text2;
    @media (max-width: 520px) {
        display: block;
        margin-top: 20px;
        color: $textGrayL3;
    }
}

.wikiSvg{
    width: 19px;
    height: 24px;
    fill: $backgroundNoStarsPlate;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    @media (max-width: 1024px) {
        top: 20px;
        right: 20px;
    }
    @media (max-width: 700px) {
        top: 15px;
        right: 15px;
    }
    @media (max-width: 520px) {
        position: relative;
        margin-top: 15px;
        top: 0;
        right: 0;
    }
}

.wikiSvg:hover{
    fill: $textGrayL2;
}

.wikiSvg:active{
    fill: $accentSuper;
}