@import '../../../styles/colors';
@import '../../../styles/typography';

.addCardPlate{
    min-width: 250px;
    height: 150px;
    cursor: pointer;
    @extend %radius;
    @extend %transition;
    border: 1px dashed $primaryBase;
    color: $textAccent;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        min-width: 230px;
        height: 140px;
    }
    @media (max-width: 520px) {
        min-width: 200px;
        height: 120px;
    }
}

.addCardPlate:hover{
    border: 1px dashed $backgroundBasePlus;
    color: $backgroundBasePlus;
}

.addCardPlate:active{
    border: 1px dashed $accentSuper;
    color: $accentSuper;
}

.addCardText{
    color: inherit;
}