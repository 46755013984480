@import '../../styles/colors';
@import '../../styles/typography';

.filterTitle{
    color: $textDarkL1;
    margin-top: 36px;
    cursor: default;
    @media (max-width: 624px) {
        margin-top: 20px;
    }
}

.filterElement{
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
    @media (max-width: 624px) {
        margin-top: 15px;
    }
}

.filterText{
    color: $textGrayL2;
    cursor: pointer;
    @extend %text2;
    @extend %no-transposition;
    display: flex;
    align-items: center;
}

.filterCount{
    color: $textDarkL1;
    @extend %text3;
    @extend %no-transposition;
    background-color: $backgroundNoStarsPlate;
    padding: 1px 3px 1px 3px;
    @extend %radius;
    font-size: 8px;
    margin-left: 8px;
    margin-top: 3px;
    @media (max-width: 520px) {
        margin-left: 6px;
        font-size: 6px;
        margin-top: 1px;
    }
}

.filterTextDop{
    color: $textGrayL3;
    cursor: pointer;
    @extend %text2;
    @extend %no-transposition;
    margin-bottom: 20px;
    margin-top: 10px;
}

.filterTextDop:hover{
    color: $textDarkL1;
}

.filterTextDop:active{
    color: $textGrayL2;
}