@import '../../../styles/colors';
@import '../../../styles/typography';

.favoritesButton{
    width: 60px;
    height: 60px;
    background: $backgroundLight;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    @extend %transition;
    @extend %radius;
    fill: $primaryBase;
    @media (max-width: 1024px) {
        width: 50px;
        height: 50px; 
    }
    @media (max-width: 520px) {
        width: 45px;
        height: 45px; 
    }
}

.favoritesButton:hover{
    background: $backgroundNoStarsPlate;
    fill: $textLight;
}

.favoritesButton:active{
    background: $textGrayL2;
}

.favoritesSvg{
    height: 25px;    
    fill: inherit;
    @media (max-width: 1024px) {height: 20px;}
    @media (max-width: 520px) {height: 15px;}
}