@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.minHeight{
    min-height: calc(100vh - 680px);
    @media (max-width: 1024px) {
        min-height: calc(100vh - 570px);
    }
    @media (max-width: 520px) {
        min-height: calc(100vh - 495px);
    }
}

.feedbackDescription{
    color: $textGrayL2;
    cursor: default;
}

.callPlate{
    position: relative;
    display: flex;
    width: 100%;
    height: 200px;
    margin-top: 40px;
    margin-bottom: 25px;
    @media (max-width: 1024px) {
        margin-top: 35px;
        height: 430px;
    }
    @media (max-width: 650px) {
        margin-top: 25px;
        height: 620px;
        flex-direction: column;
        margin-bottom: 20px;
    }
}

.linkCardPlate{
    display: flex;
    @media (max-width: 1024px) {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
    }

    @media (max-width: 1024px) {
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (max-width: 520px) {
        width: 100%;
    }
}

.linkCard{
    width: 160px;
    height: 200px;
    @extend %radius;
    @extend %shadow;
    margin-right: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 1100px) {
        margin-right: 40px;
    }
    @media (max-width: 650px) {
        width: 45%;
        margin-right: 0;
        margin-bottom: 25px;
    }
}

.linkCard:hover{
    transform: scale(1.0075);
}


.linkCardImage{
    width: 100px;
    height: 100px;
    @media (max-width: 650px) {
        width: 70px;
        height: 70px;
    }
}

.linkCardText{
    color: $textDarkL1;
}
