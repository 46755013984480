@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.cardTitle{
    color: $textDarkL1;
    cursor: default;
    margin-bottom: 37px;
    @media (max-width: 1024px) {margin-bottom: 32px;}
    @media (max-width: 520px) {margin-bottom: 22px;}
}
