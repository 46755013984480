@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.gridPlate{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 249px);
    grid-column-gap: calc((100% - (249px * 4))/3);
    grid-row-gap: 20px;
    @media (max-width: 1470px) {
        grid-template-columns: repeat(3, 249px);
        grid-column-gap: calc((100% - (249px * 3))/2);
    }
    @media (max-width: 1090px) {
        grid-template-columns: repeat(2, 249px);
        grid-column-gap: calc((100% - (249px * 2))/1);
    }
    @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 209px);
        grid-column-gap: calc((100% - (209px * 3))/2);
    }
    @media (max-width: 920px) {
        grid-template-columns: repeat(2, 209px);
        grid-column-gap: calc((100% - (209px * 2))/1);
    }
    @media (max-width: 690px) {

        grid-template-columns: repeat(1, 209px);
        grid-column-gap: calc((100% - (209px * 1))/1);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 625px) {
        display: grid;
        grid-template-columns: repeat(2, 209px);
        grid-column-gap: calc((100% - (209px * 2))/1);
    }
    @media (max-width: 520px) {
        grid-template-columns: repeat(2, 178px);
        grid-column-gap: calc((100% - (178px * 2))/1);
    }
    @media (max-width: 370px) {
        grid-template-columns: repeat(1, 178px);
        grid-column-gap: calc((100% - (178px * 1))/1);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

%margin{
    margin-top: 40px;
    @media (max-width: 1024px) {
        margin-top: 35px;
    }
    @media (max-width: 520px) {
        margin-top: 25px;
    }
}

.plate404{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    @media (min-width: 1024px) {
        height: calc(100vh - 680px);
    }
    @media (max-width: 1024px) {
        height: calc(100vh - 565px);
        min-height: 350px;
    }
    @media (max-width: 520px) {
        height: calc(100vh - 495px);
        min-height: 300px;
    }
}

.title404{
    width: 100%;
    font-family: 'Comfortaa';
    @extend %title-font; 
    font-size: 128px;
    text-align: center;
    color: $textAccent;
    cursor: default;
    font-weight: 700;
    @extend %margin;
    @media (max-width: 1024px) {font-size: 96px;}
}

.titleDesc{
    font-family: 'Comfortaa';
    width: 100%;
    @extend %title-font; 
    font-size: 24px;
    text-align: center;
    color: $textDarkL1;
    cursor: default;
    @extend %margin;
    @media (max-width: 1024px) {font-size: 20px;}
}

.titleDescMin{
    font-family: 'Comfortaa';
    width: 100%;
    @extend %title-font; 
    font-size: 16px;
    text-align: center;
    color: $textGrayL2;
    cursor: default;
    @extend %margin;
    @media (max-width: 1024px) {font-size: 14px;}
}

.buttonPlate{
    width: 100%;
    display: flex;
    justify-content: center;
    @extend %margin;
}