@import '../../../../styles/colors';
@import '../../../../styles/typography';

.plate{
    position: absolute;
    top: 38px;
    width: 460px;
    height: 290px;
    @extend %shadow;
    @extend %radius;
    z-index: 1000;
    background: $backgroundWhite;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
        top: 32px;
        width: 365px;
        height: 230px;
    }
    @media (max-width: 520px) {display: none;}
}

.plateHide{
    display: none;
}

.countriesPlate{
    width: 70px;
    height: 222px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1024px) {
        margin-left: 20px;
        width: 60px;
        height: 190px;
    }
}

.countriesBox{
    width: 70px;
    height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1024px) {
        width: 60px;
        height: 54px;
    }
    color: $textDarkL1;
    @extend %transition;
}

.countriesBox:hover{
    color: $textGrayL2;
}

.countriesSvg{
    width: 41px;
    min-height: 41px;
    @media (max-width: 1024px) {
        width: 32px;
        height: 32px;
    }
}

.countriesText{
    margin-top: 5px;
    color: inherit;
    @media (max-width: 1024px) {
        margin-top: 2px;
    }
    @extend %transition;
}

.towsPlate{
    width: 280px;
    height: 222px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        width: 230px;
        height: 190px;
    }
}

.towsText{
    width: 140px;
    text-align: left;
    height: 16px;
    color: $textGrayL2;
    @extend %text3;
    @media (max-width: 1024px) {
        width: 115px;
        height: 14px;
    }
    cursor: pointer;
    @extend %transition;
}

.towsText:hover{
    color: $textGrayL3;
}