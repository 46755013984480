@import '../../../../../../../styles/colors';

.dot {
  opacity: 0.3;
  background: $backgroundLight;
  border-radius: 360px;
  height: 10px;
  margin: 5px;
  width: 10px;

  @media (max-width: 1024px) {
    width: 8px;
    height: 8px;
    margin: 4px;
  }

  @media (max-width: 520px) {
    width: 6px;
    height: 6px;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &.selected {
    opacity: 1;
  }
}
