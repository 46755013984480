@import '../../../../styles/colors';
@import '../../../../styles/typography';


.imagePad{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    @media (max-width: 1024px) {margin-bottom: 15px;}
    @media (max-width: 520px) {margin-bottom: 10px;}
}

.imageBlockPad{
    position: relative;
    width: 180px;
    height: 180px;
    @media (max-width: 1024px) {
        width: 160px;
        height: 160px;
    }
}

.modalImg{
    width: 180px;
    height: 180px;
    @extend %radius;
    @media (max-width: 1024px) {
        width: 160px;
        height: 160px;
    }
}

.imageForm{
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    overflow: hidden;
    line-height: 0;
}

.changeImage{
    position: absolute;
    top: 0;
    cursor: pointer;
    width: 180px;
    height: 180px;
    background: rgba(0, 0, 0, 0.0);
    @extend %transition;
    color: rgba(0, 0, 0, 0.0);
    @extend %text1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        width: 160px;
        height: 160px;
    }
}

.changeImage:hover{
    @extend %blur;
    color: $backgroundWhite;
}