@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.currentBonusPlate{
    width: 340px;
    min-width: 340px;
    height: 185px;
    @extend %radius;
    background: $backgroundBase;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        width: 295px;
        min-width: 245px;
    }

    @media (max-width: 700px) {
        width: 235px;
        min-width: 235px;
        height: 165px;
    }

    @media (max-width: 512px) {
        width: 100%;
        min-width: 100%;
        height: 145px;
    }
    
}

.currentBonusPlateText{
    color: $textLight;
}