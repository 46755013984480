@import '../../../../../styles/colors';
@import '../../../../../styles/typography';

.MobilePlate{
    display: none;
    @media (max-width: 624px) {
        //margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
    }
}

.mobileButtonsPlate{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.MobileCategoriesTitle{
    color: $textGrayL2;
    @extend %text1;
}

.MobileCategories{
    margin-top: 15px;
    margin-left: 10px;
    color: $textGrayL2;
    cursor: pointer;
    @extend %text2;
    @extend %no-transposition;
    @extend %transition;
}
.MobileCategories:hover{
    color: $textGrayL3;
}
.MobileCategories:active{
    color: $accentSuper;
}

.countLine{
    margin-top: 10px;
    width: 100%;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    @media (max-width: 1024px) {
        margin-bottom: 30px;
        padding-right: 15px;
    }
    @media (max-width: 624px) {
        margin-top: 25px;
        margin-bottom: 0;
        padding-right: 10px;
    }
}

.titleColor{
    color: $textDarkL1;
}