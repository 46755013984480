@import '../../../../styles/colors';
@import '../../../../styles/typography';

.plate{
    display: flex;
    flex-direction: column;
}

.finishTitle{
    width: 320px;
    color: $textGrayL2;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 1024px) {
        width: 295px;
    }
    @media (max-width: 520px) {
        width: 240px;
    }
}

.finishText2{
    width: 320px;
    @extend %text3;
    color: $textGrayL2;
    margin-bottom: 20px;
    line-height: 20px;
    text-align: center;
    @media (max-width: 1024px) {
        width: 295px;
    }
    @media (max-width: 520px) {
        width: 240px;
        line-height: 14px;
    }
}

.finishText3{
    width: 320px;
    @extend %text3;
    color: $textGrayL3;
    margin-bottom: 20px;
    line-height: 20px;
    text-align: center;
    @media (max-width: 1024px) {
        width: 295px;
    }
    @media (max-width: 520px) {
        width: 240px;
        line-height: 14px;
    }
}