@import '../../styles/colors';
@import '../../styles/typography';

.labelText{
  @extend %text2;
  color: $textGrayL3;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @extend %transition;
}

.labelText:hover{
  color: $textGrayL2;
}

.checkbox{
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 14px;
  height: 14px;
  border: 1px solid $textGrayL3;
  @extend %radius;
  @extend %shadow;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  @extend %transition;
  cursor: pointer;
}

.checkbox:hover{
  border: 1px solid $textGrayL2;
}


.checkbox:checked{
  background-color: $backgroundBase;
}

.checkbox::before {
  content: "";
  width: 14px;
  height: 14px;
  background-color: $textLight;
  clip-path: path('M 14.71044 1.620888 C 14.61748 1.518954 14.50688 1.438048 14.38502 1.381632 C 14.26315 1.325216 14.13308 1.294972 14.00044 1.292632 C 13.8678 1.290292 13.73773 1.316856 13.61587 1.371048 C 13.49402 1.42524 13.38342 1.506146 13.29044 1.609788 L 5.84044 9.734 L 2.71044 6.3191 C 2.61392 6.243312 2.50798 6.184944 2.39424 6.147692 C 2.28051 6.11044 2.16144 6.095496 2.04044 6.103196 C 1.91944 6.110896 1.80137 6.141896 1.68844 6.194608 C 1.57551 6.24732 1.46957 6.320368 1.37444 6.410444 C 1.27931 6.50052 1.19685 6.606304 1.12901 6.724172 C 1.06117 6.84204 1.00986 6.970352 0.97844 7.104436 C 0.947019 7.23852 0.935793 7.37614 0.945442 7.51308 C 0.955091 7.65002 0.985454 7.783416 1.03444 7.906276 C 1.08343 8.029137 1.15068 8.138002 1.23244 8.227108 L 5.13044 12.09396 C 5.19847 12.16164 5.28015 12.21332 5.37044 12.24506 C 5.46074 12.2768 5.55638 12.28832 5.65394 12.27974 C 5.7515 12.27116 5.84825 12.24258 5.93994 12.19506 C 6.03163 12.14754 6.11679 12.08146 6.19194 11.99996 C 6.26709 11.91847 6.33186 11.82229 6.38188 11.71604 L 14.71044 3.241776 C 14.79548 3.142978 14.86952 3.02955 14.92836 2.9046 C 14.98721 2.77965 15.03028 2.644882 15.05636 2.504268 C 15.08243 2.363654 15.09128 2.218938 15.08276 2.07424 C 15.07425 1.929542 15.04838 1.787748 15.006 1.651868 C 14.9636 1.515988 14.90567 1.38887 14.83344 1.275148 C 14.7612 1.161427 14.67523 1.062748 14.5774 0.98118 C 14.47957 0.899611 14.3702 0.83689 14.255 0.795044 C 14.1398 0.753198 14.02023 0.732572 13.90044 0.734124 C 13.78064 0.735675 13.66207 0.759404 13.55244 0.804444 Z');
  transform: scale(0.7);
}