@import '../../styles/colors';
@import '../../styles/typography';

.input{
    height: 0;
    width: 0;
    visibility: hidden;
}
  
.label {
    cursor: pointer;
    text-indent: -9999px;
    width: 34px;
    height: 18px;
    background: $textGrayL2;
    display: block;
    border-radius: 100px;
    position: relative;
}
  
.label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    background: $backgroundWhite;
    border-radius: 90px;
    transition: 0.3s;
}
  
.input:checked + .label {
    background: $primaryBase;
}
  
.input:checked + .label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
}
  
.label:active:after {
    width: 32px;
}